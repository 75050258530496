import React from 'react'

export default function PaintBucket() {
  return (
    <svg
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      style={{ width: 38, height: 38 }}
    >
      <path
        d="M40 17l-32 .004V43a3 3 0 003 3h26a3 3 0 003-3V17"
        fill="#cfd8dc"
      />
      <path
        d="M11.813 17v.012c1.37.054 2.566.695 3.285 2.023.879 1.617.972 3.73 2.687 4.719 2.395 1.379 3.574-.442 5.594-1.129 2.351-.8 3.805 1.332 4.488 3.137.66 1.746.957 3.847 1.133 5.863.172 1.965.527 4.84 1.629 6.473 1.195 1.77 3.707 2.304 4.871.285 1.078-1.871.25-16.965 4.5-21.383H11.812zm0 0"
        fill="#29b0fa"
      />
      <path
        d="M42 18.832C42 9.551 33.926 2 24 2S6 9.55 6 18.832V26h2v-7.168C8 10.652 15.18 4 24 4s16 6.652 16 14.832V26h2v-7.168zm0 0"
        fill="#546e7a"
      />
    </svg>
  )
}
